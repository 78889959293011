<template>
  <div class="custom-date-picker">
    <el-date-picker
        v-if="!isSingleStyle"
        v-model="choiceTime"
        :type="dateType"
        align="right"
        unlink-panels
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :format="format"
        :value-format="format"
        :picker-options="pickerOptions"
        @change="change">
    </el-date-picker>
    <el-date-picker
        v-else
        v-model="choiceTime"
        :type="dateType"
        align="right"
        placeholder="选择日期时间"
        unlink-panels
        :format="format"
        :value-format="format"
        :picker-options="pickerOptions"
        @change="change">
    </el-date-picker>
  </div>
</template>

<script>
/**
 * 自定义时间选择器，开始时间和结束时间
 *
 * 回调方法 handelChange：
 *  返回一个数组，第一个参数为开始时间，第二个为结束时间。
 *
 */
export default {
  props: {
    format: {
      type: String,
      default: 'yyyy-MM-dd HH:mm:ss'
    },
    dateType: {
      type: String,
      default: 'daterange'
    },
    isSingleStyle: {
      type: Boolean,
      default: false
    },
    defaultValue: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      choiceTime: [],
      // 时间选择的数据
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              const start = new Date()
              const end = new Date()
              // end.setDate((end.getDate() + 1))
              console.log(start)
              start.setHours(0); start.setMinutes(0); start.setSeconds(0)
              end.setHours(23); end.setMinutes(59); end.setSeconds(59)
              console.log('今天', start, end)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '昨天',
            onClick(picker) {
              const start = new Date()
              start.setDate((start.getDate() - 1))
              const end = new Date()
              end.setDate((end.getDate() - 1))
              start.setHours(0); start.setMinutes(0); start.setSeconds(0)
              end.setHours(23); end.setMinutes(59); end.setSeconds(59)
              console.log('昨天', start, end)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近7天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              start.setHours(0); start.setMinutes(0); start.setSeconds(0)
              end.setHours(23); end.setMinutes(59); end.setSeconds(59)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近30天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              start.setHours(0); start.setMinutes(0); start.setSeconds(0)
              end.setHours(23); end.setMinutes(59); end.setSeconds(59)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              start.setHours(0); start.setMinutes(0); start.setSeconds(0)
              end.setHours(23); end.setMinutes(59); end.setSeconds(59)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '本月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setDate(1)
              start.setHours(0); start.setMinutes(0); start.setSeconds(0)
              end.setHours(23); end.setMinutes(59); end.setSeconds(59)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '上月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              console.log('天数' + start.getMonth())
              start.setMonth((start.getMonth() - 1))
              start.setDate(1)
              end.setDate(1)
              end.setTime(end.getTime() - 3600 * 1000 * 24 * 1)
              start.setHours(0); start.setMinutes(0); start.setSeconds(0)
              end.setHours(23); end.setMinutes(59); end.setSeconds(59)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      }
    }
  },
  mounted() {
    this.choiceTime = this.defaultValue
  },
  methods: {
    change(val) {
      console.log(val)
      if (val) {
        const start = val[0]
        const endTime = val[1].replace('00:00:00', '23:59:59')
        this.$emit('handelChange', [start, endTime])
      } else {
        this.$emit('handelChange', [])
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.custom-date-picker {
  ::v-deep .el-input__inner {
    max-width: 100%;
  }
}
</style>
