<template>
  <div class="title">
    {{title}}
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: ()=>'基本设置'
    }
  }
}
</script>

<style scoped lang="scss">
.title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 24px;
  color: #2D3748;
  margin-bottom: 20px;
}
</style>
